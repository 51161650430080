body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(80, 82, 81);
  background-image: url(./assets/images/cielo6.jpg);  
  background-position:center;
  background-size: cover;  
  width: 100vw;
  height: 100vh;
  margin: auto;
}
.modal-content {
  background-color: rgb(147, 156, 147);
  opacity: 0.9;
  text-align: center;
}

.slider-container {
  width: 50%;
  height: auto; 
  position:fixed;
  top:25%;
  left:25%;  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {  
  display: grid; 
  text-align: center;  
  padding: 1rem;
  grid-gap: 1rem;     
  grid-template-areas: 
  "logo bar cart"
  "greeting greeting greeting"    
  ;
}

.navbar {    
  grid-area: bar;
  }

.greeting{
  grid-area: greeting;
}

.cartWidget{
  grid-area: cart;
  margin-top: 1rem;
}

.App-logo {  
  pointer-events: none;
  grid-area: logo;
  width: 3rem;
  height: 3rem;
  opacity: 0.6;
}

.cardSize{
  width: 10rem;
  height: 14rem;
}

.cardSize:hover{
line-height:2rem;
}

.textStyle{
  color:rgb(38, 204, 204);
}

.tableStyle{
  margin: auto;
  width: 50%;
  justify-content: center;
  color:darkgray;
}

.item{
  display: grid;    
  grid-gap: 1rem;
  padding: 1rem;  
  justify-content: center;
  margin: auto;
  grid-template-areas: 
  "itemArea itemArea itemArea itemArea"
  ;
}

.viewBar{
  visibility: visible;
}
.viewButton{
  visibility: hidden;
}

@media (max-width: 768px) {
  .item {
    display: flex;
    flex-direction: column;    
  }  
  .cardSize{
    width:23rem;
    height: auto;   
    margin: auto;     
  }
  .tableStyle{
    margin: auto;
    width: 100%;
  }
  .slider-container {     
    height: auto;
    width: auto;   
    top:25%;
    left:0%;  }
  .viewBar{
      visibility: hidden;
  }
  .viewButton{
    visibility: visible;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
